





































































































import Vue from "vue";
import Component from "vue-class-component";

import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";
import RequestButton from "@/components/basic/RequestButton.vue";

import { LocalStorage } from "../../storage/LocalStorage";

import { CreateParameterViewModel } from "@/plant/vms/CreateParameterViewModel";
import { CreateParameterPresenter } from "@/plant/presenters/CreateParameterPresenter";
import { CreateParameterController } from "@/plant/controllers/CreateParameterController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { PlantGraphQLService } from "../services/PlantGraphQLService";
import { PlantParameter, Plant } from "../services/PlantService";

@Component<CreateParameterView>({
  components: {
    Card,
    Container,
    Column,
    Row,
    RequestButton
  },
  props: {
    plant: {},
    parameter: {}
  },
  watch: {
    "vm.goBack"() {
      this.vm.visibleView = false;
      this.$emit("closed");
      this.$destroy();
    },
    "vm.updatedPlant"() {
      this.vm.visibleView = false;
      this.$emit("finished");
      this.$destroy();
    }
  }
})
export default class CreateParameterView extends Vue {
  private vm = new CreateParameterViewModel();
  private controller?: CreateParameterController;
  private plant!: Plant;
  private parameter!: PlantParameter;

  private initController(): CreateParameterController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new CreateParameterController(
      new CreateParameterPresenter(this.vm, this.plant, this.parameter),
      new PlantGraphQLService(connection)
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted();
  }
}

import { IPlantService, Plant, PlantParameter } from "../services/PlantService";
import { FormResponse } from "@/forms/FormResponse";
import { FormRequestHandler } from "@/forms/FormRequestHandler";

export class CreateParameterController {
  public constructor(
    private presenter: ICreateParameterPresenter,
    private plantService: IPlantService
  ) {}

  public mounted() {
    this.presenter.visibleView = true;
  }

  public closeButtonClicked(): void {
    this.presenter.visibleView = false;
  }

  public parameterSelected(parameter: { value: string; text: string }): void {
    this.presenter.parameterSelected(parameter);
  }

  public removeSelectedParameter(index: number) {
    this.presenter.removeSelectedParameter(index);
  }

  public updatePlantButtonClicked() {
    const plant = this.presenter.plant;
    plant.parameters = plant.parameters.map((param: any) => ({
      parameterId: param.id,
      value: param.value
    }));

    const request = this.plantService.addPlantParameters(
      plant,
      this.presenter.selectedParameters
    );
    FormRequestHandler.handle(
      request,
      response => (this.presenter.updatePlantResponse = response),
      "update-plant-failed"
    );
  }
}

export interface ICreateParameterPresenter {
  plant: Plant;
  visibleView: boolean;

  updatePlantResponse: FormResponse<string>;

  selectedParameters: PlantParameter[];

  parameterSelected(parameter: { text: string; value: string }): void;
  removeSelectedParameter(index: number): void;
}

import { Selection, Textfield, Request } from "@/forms/ViewModelFormTypes";

export class CreateParameterViewModel {
  public plant: any = {};
  public visibleView: boolean = false;
  public goBack: boolean = false;
  public updatedPlant: string = "";

  public updatePlantRequest: Request = { loading: false, error: "" };

  public parameter: Selection = { selected: "", error: "", items: [] };
  public suffix = "";

  public selectedParameters: SelectedParameter[] = [];
}

export interface SelectedParameter {
  name: { value: string; text: string };
  value: string;
  type?: string;
  selection?: string[];
}

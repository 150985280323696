import { ICreateParameterPresenter } from "../controllers/CreateParameterController";
import { CreateParameterViewModel } from "../vms/CreateParameterViewModel";
import { FormResponse } from "@/forms/FormResponse";
import { Plant, PlantParameter } from "../services/PlantService";
import { ParameterStorageHandler } from "@/storage/storageHandlers/ParameterStorageHandler";

export class CreateParameterPresenter implements ICreateParameterPresenter {
  public editMode: boolean;

  public constructor(
    private vm: CreateParameterViewModel,
    plant: Plant,
    parameter: PlantParameter
  ) {
    this.vm.plant = plant;
    this.editMode = !!parameter;
    this.initSelections(plant);

    if (!!parameter) {
      this.initParameterFromParameter(parameter);
    }
  }

  public set visibleView(visible: boolean) {
    this.vm.visibleView = visible;
    if (!visible) {
      setTimeout(() => (this.vm.goBack = true), 300);
    }
  }

  public parameterSelected(parameter: { text: string; value: string }) {
    const foundParameterIndex = this.vm.parameter.items.findIndex(
      el => el.value === parameter.value
    );
    if (foundParameterIndex > -1) {
      this.vm.parameter.items.splice(foundParameterIndex, 1);
    }

    const param = ParameterStorageHandler.getParameterFromId(parameter.value);

    let value = "";
    if (param && param.limit && param.limit !== "0") {
      value = param.limit;
    }

    this.vm.selectedParameters.push({
      name: parameter,
      value,
      type: param.type,
      selection: param.selection
    });
  }

  public removeSelectedParameter(index: number) {
    const parameter = this.vm.selectedParameters.splice(index, 1);

    this.vm.parameter.items.push(parameter[0].name);
    this.vm.parameter.items.sort((a, b) => a.text.localeCompare(b.text));
  }

  public set updatePlantResponse(response: FormResponse<string>) {
    this.vm.updatePlantRequest = response;

    if (!response.loading && !response.error) {
      this.vm.updatedPlant = response.data;
    }
  }

  public get plant(): Plant {
    return this.vm.plant;
  }

  public get selectedParameters(): PlantParameter[] {
    return this.vm.selectedParameters.map(el => ({
      parameterId: el.name.value,
      value: el.value
    }));
  }

  private initSelections(plant: Plant) {
    this.vm.parameter.items = ParameterStorageHandler.getAllParameters();

    if (!this.editMode) {
      for (const parameter of plant.parameters) {
        const index = this.vm.parameter.items.findIndex(
          el => el.value === (parameter as any).id
        );

        if (index >= 0) {
          this.vm.parameter.items.splice(index, 1);
        }
      }
    }
  }

  private initParameterFromParameter(parameter: PlantParameter) {
    const templateParameter = ParameterStorageHandler.getParameterFromId(
      parameter.parameterId
    );

    this.vm.selectedParameters.push({
      name: {
        value: parameter.parameterId,
        text: templateParameter.name || parameter.parameterId
      },
      value: parameter.value,
      type: templateParameter.type,
      selection: templateParameter.selection
    });
  }
}
